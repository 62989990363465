import React from 'react';
import { device } from 'components/device';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${props =>
    props.personalization ? '1.875rem 0 4.375rem' : '4.375rem 0'};
  gap: 1.875rem;
  flex-wrap: wrap;

  @media ${device.laptop} {
    gap: 1.25rem;
    padding: ${props =>
      props.personalization ? '1.25rem 0 1.875rem' : '1.875rem 0'};
  }
`;

const ContainerMain = styled.div`
  display: flex;
  flex-direction: column;
`;

const TitleContainer = styled.div`
  display: flex;

  h2 {
    margin: 4.375rem 0 0;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 30px;

    @media ${device.laptop} {
      margin: 1.875rem 0 0;
      font-size: 18px;
      line-height: 20px;
    }
  }
`;

function CardGroupWorkWithUs({ children, className = '', titleCostumized }) {
  return (
    <ContainerMain>
      {titleCostumized && (
        <TitleContainer>
          <h2>{titleCostumized}</h2>
        </TitleContainer>
      )}
      <Container
        personalization={titleCostumized ? true : false}
        className={className}
      >
        {children}
      </Container>
    </ContainerMain>
  );
}

export default CardGroupWorkWithUs;
