import React, { useEffect, useState } from 'react';
import {
  Container,
  ContainerContent,
  Content,
  ContentImg,
  ContainerTitle,
  Title,
  ContainerBox,
  ContainerButton,
  Button,
  FirstBlob,
  SecondBlob,
  ContainerImg,
} from './style';

export function PresentationArea({
  markdown,
  fragments,
  asset,
  isWidthContent,
  isMaxWidthContent,
  isWidthTitle,
  isWidthTitleMob,
  isMargintopButton,
  blobs,
  isCostumizedPaddingBottom,
  isTarget,
}) {
  const [tablet, setTablet] = useState(false);

  let dataInfor = '';
  let title = '';
  let content = '';
  if (markdown && fragments) {
    const markdownSplitted = markdown.split('(end)');
    title = markdownSplitted[0];
    content = markdownSplitted[1];
    dataInfor = fragments[0].datafragment[0]?.data;
  }

  const updateMedia = () => {
    if (typeof window !== 'undefined') {
      setTablet(window.screen.availWidth <= 768);
    }
  };
  useEffect(() => {
    if (typeof window !== 'undefined') {
      updateMedia();
    }

    if (typeof window !== 'undefined') {
      window.addEventListener('resize', updateMedia);
      return () => window.removeEventListener('resize', updateMedia);
    }
  }, []);

  return (
    <Container isCostumizedPaddingBottom={isCostumizedPaddingBottom}>
      <ContainerContent>
        <ContainerBox
          isWidthContent={isWidthContent}
          isMaxWidthContent={isMaxWidthContent}
        >
          <ContainerTitle isWidthTitle={isWidthTitle}>
            <Title isWidthTitleMob={isWidthTitleMob}>{title}</Title>
            {tablet && (
              <ContainerImg>
                {blobs && <FirstBlob src={blobs.fistBlobPink} alt="Blob" />}
                <ContentImg
                  image={asset}
                  className="Image-Teaching"
                  alt="Image Teaching and Research"
                  fadeIn={true}
                  withWebp={true}
                  loading="lazy"
                />
                {blobs && <SecondBlob src={blobs.secBlobBlue} alt="Blob" />}
              </ContainerImg>
            )}
          </ContainerTitle>
          <Content escapeHtml={false} children={content} />

          {dataInfor ? (
            <ContainerButton isMargintopButton={isMargintopButton}>
              <Button target={isTarget} to={dataInfor.link}>
                {dataInfor.name}
              </Button>
            </ContainerButton>
          ) : null}
        </ContainerBox>
      </ContainerContent>
      {!tablet && (
        <ContainerImg>
          {blobs && <FirstBlob src={blobs.fistBlobPink} alt="Blob" />}
          <ContentImg
            image={asset}
            className="Image-Teaching"
            alt="Image Teaching and Research"
            fadeIn={true}
            withWebp={true}
            loading="lazy"
          />
          {blobs && <SecondBlob src={blobs.secBlobBlue} alt="Blob" />}
        </ContainerImg>
      )}
    </Container>
  );
}
