import React from 'react';
import ReactMarkdown from 'react-markdown';
import { device } from 'components/device';
import styled from 'styled-components';
import { Link } from 'gatsby';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${props =>
    props.isDoctorSupport
      ? 'unset'
      : props.isFastTrack
      ? 'flex-start'
      : 'space-between'};
  gap: ${props => (props.isFastTrack ? '1.25rem' : '1.875rem')};
  width: 100%;
  max-width: ${props => (props.cardPersonalized ? '16.875rem' : '23.125rem')};
  height: 100%;
  min-height: ${props =>
    (props.isDoctorSupport && '20.5rem') ||
    (props.cardPersonalized && '22.5rem') ||
    (props.isMainExam && '22rem')};
  max-height: ${props => props.isFastTrack && '22.5rem'};
  border: 1px solid #c6c8cc;
  border-radius: 0.5rem;
  padding: 1.875rem;

  h2 {
    font-size: 1.5rem;
    line-height: 1.875rem;
    color: #000;
    font-weight: 600;
    margin: 0;

    em {
      display: block;
      font-style: normal;
      font-size: 1rem;
      line-height: 0.75rem;
    }

    a {
      color: #000;
      line-height: ${props => props.isFastTrack && '0.625rem'};

      em {
        display: block;
        font-style: normal;
        font-size: 1rem;
      }
    }
  }

  p {
    font-size: 0.875rem;
    line-height: 1.375rem;
    color: ${props => (props.isDoctorSupport ? '#231F20' : '#8f9194')};
    width: ${props => props.isTextPersonalized && '19.375rem'};

    :nth-child(2) {
      height: ${props => props.isMainExam && '7.8125rem'};

      @media ${device.laptop} {
        height: ${props => props.isMainExam && 'unset'};
      }
    }

    a {
      color: ${props =>
        (props.isDoctorSupport && '#45A7DF') ||
        (props.isWorkWithUs && '#8f9194')};
    }

    strong {
      display: ${props => props.isDoctorSupport && 'block'};
    }

    em {
      display: ${props => props.isDoctorSupport && 'block'};
    }

    @media ${device.laptop} {
      width: ${props => props.isTextPersonalized && 'unset'};
    }
  }

  @media ${device.laptop} {
    max-width: unset;
    gap: 1.25rem;
    min-height: ${props =>
      (props.isMainExam && 'unset') || (props.cardPersonalized && 'unset')};

    h2 {
      font-size: 1.125rem;
      line-height: ${props => (props.isFastTrack ? '1.25rem' : '1.375rem')};
    }
  }
`;

function CardWorkWithUs({
  contentMarkdown = '',
  isDoctorSupport,
  className = '',
  isWorkWithUs,
  isFastTrack,
  cardPersonalized,
  isTextPersonalized,
  isMainExam,
}) {
  const LinkRender = ({ href, children }) => {
    const isInternalLink = href.startsWith('/');

    if (isInternalLink) {
      return <Link to={href}>{children}</Link>;
    }

    return (
      <a href={href} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    );
  };
  return (
    <Container
      isDoctorSupport={isDoctorSupport}
      className={className}
      isWorkWithUs={isWorkWithUs}
      isFastTrack={isFastTrack}
      cardPersonalized={cardPersonalized}
      isTextPersonalized={isTextPersonalized}
      isMainExam={isMainExam}
    >
      <ReactMarkdown
        children={contentMarkdown}
        escapeHtml={false}
        renderers={{
          link: LinkRender,
        }}
      />
    </Container>
  );
}

export default CardWorkWithUs;
