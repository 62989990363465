import styled from 'styled-components';
import { device } from '../device';
import ReactMarkdown from 'react-markdown';
import GraphImg from 'graphcms-image';
import { Link } from 'gatsby';

export const Container = styled.div`
  display: flex;
  margin-top: 4.375rem;
  margin-bottom: ${props =>
    props.isCostumizedPaddingBottom
      ? props.isCostumizedPaddingBottom
      : '4.375rem'};

  @media ${device.tablet} {
    flex-direction: column;
    margin-top: 0;
    margin-bottom: 1.875rem;
  }
`;

export const ContainerContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 50%;

  @media ${device.tablet} {
    max-width: 100%;
  }
`;

export const ContainerBox = styled.div`
  display: flex;
  flex-direction: column;
  width: ${props => props.isWidthContent && props.isWidthContent};
  max-width: ${props => props.isMaxWidthContent && props.isMaxWidthContent};

  @media ${device.tablet} {
    max-width: 100%;
  }
`;

export const ContainerTitle = styled.div`
  display: flex;
  width: ${props => props.isWidthTitle && props.isWidthTitle};

  @media ${device.tablet} {
    flex-direction: column;

    width: 100%;
  }
`;

export const ContainerButton = styled.div`
  display: flex;

  margin-top: ${props => props.isMargintopButton && props.isMargintopButton};

  @media ${device.laptop} {
    margin-top: ${props => props.isMargintopButton && '1.875rem'};
  }
`;

export const Button = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 16rem;
  height: 2.8125rem;
  background-color: #45a7df;
  color: #ffffff;
  border-radius: 1.4375rem;
  font-style: normal;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  font-family: Inter;

  @media ${device.mobile} {
    max-width: 100%;
    height: 3.5625rem;
    border-radius: 3.125rem;
  }
`;

export const Title = styled(ReactMarkdown)`
  display: flex;

  h2 {
    margin-bottom: 3.125rem;
    font-size: 2.25rem;
    line-height: 2.875rem;
    font-family: Inter;
    font-style: normal;

    @media ${device.tablet} {
      font-size: 1.625rem;
      line-height: 2rem;
      margin-bottom: 1.875rem;
    }

    @media ${device.mobile} {
      width: ${props => props.isWidthTitleMob && props.isWidthTitleMob};
    }
  }
`;

export const Content = styled(ReactMarkdown)`
  display: flex;
  flex-direction: column;

  p {
    font-family: Inter;
    font-style: normal;
    font-size: 1rem;
    line-height: 1.625rem;
    color: #231f20;
    margin-bottom: 1.875rem;

    :last-child {
      margin-bottom: 0;
    }

    a {
      font-family: Inter;
      font-style: normal;
      font-size: 1rem;
      line-height: 1.625rem;
      color: #45a7df;
    }

    @media ${device.tablet} {
      font-size: 0.875rem;
      line-height: 1.375rem;
      margin-bottom: 1.25rem;

      a {
        font-size: 0.875rem;
        line-height: 1.375rem;
      }
    }
  }

  ul {
    list-style: none;
    margin-left: 0;

    > li {
      display: flex;

      :last-child {
        margin-bottom: 0;
      }

      > img {
        margin-right: 0.625rem;
        width: 1.5rem;
      }
    }
  }
`;

export const ContentImg = styled(GraphImg)`
  display: flex;
  width: 35.625rem;

  @media ${device.laptop} {
    width: 29.375rem;
  }

  @media ${device.mobile} {
    width: 20.9375rem;
  }

  @media ${device.mobile320} {
    width: 17.5rem;
  }
`;

export const ContainerImg = styled.div`
  display: flex;
  position: relative;

  @media ${device.tablet} {
    justify-content: center;
    margin-bottom: 1.875rem;
  }
`;

export const FirstBlob = styled.img`
  width: 55%;

  z-index: -1;
  position: absolute;
  top: -1.75rem;
  left: -4rem;

  @media ${device.laptop} {
    width: 49%;
    top: -1.25rem;
    left: -2.875rem;
  }

  @media ${device.tablet} {
    width: 32%;

    top: -1.25rem;
    left: 5.625rem;
  }

  @media ${device.mobile} {
    width: 32%;
    top: -0.625rem;
    left: -1rem;
  }
`;

export const SecondBlob = styled.img`
  z-index: -1;
  position: absolute;
  width: 50%;
  bottom: 0.375rem;
  right: -2.8125rem;
  transform: rotate(256deg);

  @media ${device.laptop} {
    width: 50%;
    bottom: 5.5rem;
    right: -1.75rem;
    transform: rotate(-4deg);
  }

  @media ${device.tablet} {
    width: 33%;
    bottom: -1.5rem;
    right: 6.625rem;
  }

  @media ${device.mobile} {
    width: 40%;
    bottom: -0.8125rem;
    right: -1rem;
  }
`;
